module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Felicette","short_name":"Felicette","start_url":"/","background_color":"#1c1d21","theme_color":"#1c1d21","display":"standalone","icon":"src/images/icon.png","description":"Open source projects by Facundo Leites","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0208a5d78ae06ff9743ee09531824125"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/felicette-landing/felicette-landing/src/intl","languages":["en","es","pt","fr"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
